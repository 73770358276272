import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { LazyLoader } from 'shared';
import { useEffect } from 'react';
import { Layout } from './layout';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const App = () => {
	useEffect(() => { document.title = 'Seed Batch Tracking'; }, []);

	return <BrowserRouter>
		<SentryRoutes>
			<Route Component={Layout}>
				<Route index element={<LazyLoader loader={() => import('./pages/settings/settings')} />} />
				<Route path="core-settings" element={<LazyLoader loader={() => import('./pages/core-settings')} />} />
				<Route path="core-metrics" element={<LazyLoader loader={() => import('./pages/core-metrics')} />} />
				<Route
					path="action-select"
					element={<LazyLoader
						loader={() => import(/* webpackPrefetch: true */ './pages/action-select/action-select')}
					/>}
				/>
				<Route path="discard" element={<LazyLoader loader={() => import('./pages/stage/discard')} />} >
					<Route path="scan" element={<LazyLoader loader={() => import('./pages/scan/discard/discard')} />} />
				</Route>

				<Route
					path="action"
					element={<LazyLoader
						loader={() => import(/* webpackPrefetch: true */ './pages/stage/stage')}
					/>}
				>
					<Route
						path=":actionTypeId"
						element={<LazyLoader
							loader={() => import(/* webpackPrefetch: true */ './pages/scan/scan')}
						/>}
					/>
					<Route
						path="print/:batchId"
						element={<LazyLoader
							loader={() => import(/* webpackPrefetch: true */ './pages/print-batch/print-batch')}
						/>}
					/>
				</Route>
				<Route path="view" element={<LazyLoader loader={() => import('./pages/view/view')} />} />
				<Route path="production" element={<LazyLoader loader={() => import('./pages/stage/production')} />} >
					<Route path="new" element={<LazyLoader loader={() => import('./pages/new-batch/new-batch')} />} />
					<Route path="validate" element={<LazyLoader loader={() => import('./pages/validate/validate')} />} />
					<Route path="associate/:batchId" element={<LazyLoader loader={() => import('./pages/stage/associate/associate')} />} />
				</Route>
				<Route path="set-up">
					<Route index element={<LazyLoader loader={() => import('./pages/set-up/set-up')} />} />
					<Route path="crop">
						<Route path="new" element={<LazyLoader loader={() => import('./pages/set-up/new-crop/new-crop')} />} />
						<Route path="edit/:cropId" element={<LazyLoader loader={() => import('./pages/set-up/edit-crop/edit-crop')} />} />
					</Route>
					<Route path="recipe">
						<Route path="new/:cropId" element={<LazyLoader loader={() => import('./pages/set-up/new-recipe/new-recipe')} />} />
						<Route path="edit/:recipeId" element={<LazyLoader loader={() => import('./pages/set-up/edit-recipe/edit-recipe')} />} />
						<Route path="view/:recipeId" element={<LazyLoader loader={() => import('./pages/set-up/view-recipe/view-recipe')} />} />
					</Route>
				</Route>
				<Route path="settings" element={<LazyLoader loader={() => import('./pages/settings/settings')} />} />
				<Route path="catalog" element={<LazyLoader loader={() => import('./pages/catalog/catalog')} />}>
					<Route path="initialize" element={<LazyLoader loader={() => import('./pages/catalog/initalize')} />} />
					<Route path="new" element={<LazyLoader loader={() => import('./pages/catalog/new')} />} />
				</Route>
			</Route>
			<Route path="login" element={<LazyLoader loader={() => import('./pages/login/login')} />} />
			<Route path="logout" element={<LazyLoader loader={() => import('./pages/logout/logout')} />} />
			<Route path="invitation" element={<LazyLoader loader={() => import('./pages/invitation/invitation')} />} />
			<Route path="reset" element={<LazyLoader loader={() => import('./pages/reset/reset')} />} />
		</SentryRoutes>
	</BrowserRouter>
};
