import { useEffect, useMemo } from 'react';
import { useGetMeQuery } from './pages/login/login.generated';
import { useUpgradeReload } from './utils/upgrade-reload';
import { PageLayout } from 'shared';
import * as Sentry from '@sentry/react';
import { OrgContext } from './utils/useOrg/useOrg';
import { NDEFProvider } from './utils/useNFC';
import { GeolocationProvider } from './utils/useGPS/useGPS';

export const Layout = () => {
	useUpgradeReload();

	const [{ data }] = useGetMeQuery();
	useEffect(() => {
		if (data?.me) {
			Sentry.setUser({ email: data.me.email });
		}
	}, [data]);

	const pages = useMemo(() => {
		const sbtPages = data?.me?.org?.usesSbt ? [
			{ name: 'Batches', path: '/production' },
			{ name: 'Recipes', path: '/set-up' },
			{ name: 'Catalog', path: '/catalog' },
		] : [];
		const corePages = data?.me?.org?.usesCore ? [
			{ name: 'Metrics', path: '/core-metrics' },
			{ name: 'Cores', path: '/core-settings' },
		] : [];

		return [
			...sbtPages,
			...corePages,
			{ name: 'Settings', path: '/settings' },
		]
	}, [data]);

	return <OrgContext>
		<NDEFProvider>
			<GeolocationProvider>
				<PageLayout pages={pages} />
			</GeolocationProvider>
		</NDEFProvider>
	</OrgContext>;
};